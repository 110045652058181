<template>
  <div  class="video-modal" style="top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;" v-if="showModal">
     <div class="close-icon" @click="closeVideo">
      <v-icon>mdi-close</v-icon>
    </div>
    <div class="modal-content">
      <video ref="videoPlayer" class="video-js vjs-big-play-centered"></video>
   
    </div>
  </div>
</template>
<script>
import "video.js/dist/video-js.css";
import videojs from "video.js";

export default {
  name: "VideoPlayer",
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      player: null,
      completeCheck: true,
      isPlaying: false,
      showModal:true,
    };
  },
  computed: {
    
  },
  watch: {
  },
  methods: {
 
  
    // playTheVideo(){
    //   this.player.play()
    // },
    // pauseTheVideo(){
    //   this.player.pause()
    // },
    // playing(){
    //   this.isPlaying = true
    // },
    // paused(){
    //   this.isPlaying=false
    // },
       closeVideo() {
      this.showModal=false // Pause the video before closing
      // Emit an event to the parent to close the modal
      location.reload();
    },
    watchplayer() {
      let time = Math.round(
        (this.player.currentTime() / this.player.duration()) * 100
      );
      // console.log(time,"jdjdjd")

      if (this.$route.matched[0].path.substring(1) === "parent") {
        if (time == 80 && this.completeCheck) {
          console.log(time, "80% has done");
          this.$root.$refs.videoSection.submitCompleted(
            this.$store.state.videoSection.videoModal.videoObject,
            this.player.currentTime()
          );
          this.completeCheck = false;
        }
      }
    },
  },
  mounted() {
  this.player = videojs(this.$refs.videoPlayer, {
    fluid: true, // Make the player responsive
    aspectRatio: '16:9', // Maintain the aspect ratio
    autoplay: false,
    controls: true,
  });
    this.player = videojs(
      this.$refs.videoPlayer,
      this.options,
      function onPlayerReady() {
        // console.log("onPlayerReady", this);
      }
    );
    this.player.controlBar.progressControl.disable();
    const self = this;
    this.player.on("timeupdate",function(){
      console.log("timeupdate",self.player.currentTime(),self.player.duration())
      // alert("time")
      self.watchplayer()
      // self.player.currentTime()
    });
      this.player.on("ended", () => {
      this.$emit("video-ended");
    });
  },
  beforeDestroy() {
    this.player.dispose();
  },

};
</script>
<style scoped>
.video-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent black background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  width: 80%;
  max-width: 900px;
  background: transparent;
  padding: 0;
  border-radius: 10px;
  aspect-ratio: 16/9;
}

.video-js {
  width: 100%;
  height: auto;
}

/* Style the close button */
.close-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 32px; /* Larger size for visibility */
  color: white; /* Make the close icon white */
  cursor: pointer;
  z-index: 1001; /* Make sure the close icon is above other elements */
}

.close-icon:hover {
  color: red; /* Optional: Add hover effect to make it more interactive */
}
.theme--light.v-icon {
    /* color: rgba(0, 0, 0, 0.54); */
    color: white;
}
</style>